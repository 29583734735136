/* width */
::-webkit-scrollbar {
    width: 6px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #000000; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #36d2cd; 
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #36d2cd; 
}

body {
    background-color:rgba(0,0,0,0);
    color:#fff;
    min-height:100vh;
}

h1,h2,h3,h4,h5,h6{
    font-family:'suigeneris';
}

html, body, pre, code, kbd, samp {
    
    font-family: "roboto";
}

input:focus {
    background-color: rgba(54, 210, 205,0.1);
}

.center{
    text-align: center;
}

.space-border,
.space-border:before,
.space-border:after{
    width: 30px;
    height: 1px;
}

.space-border{
    background: #36d2cd;
    position: relative;
    margin: 0 auto;
    margin-bottom: 40px;
}

.space-border:before{
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(255, 255, 255, .25);
}
.space-border:after{
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(255, 255, 255, .25);
}

.dapp-box{
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 10px;
    background-color: transparent;
    
}

.body-content {
    max-width: 700px;
    margin: 20px auto;
    min-height:90vh;
    width:100%;
    align-items: center;
    justify-content: center;
}

.token-content {
    max-width: 700px;
    margin: 20px auto;
    width:100%;
    align-items: center;
    justify-content: center;
}

#slippageToggle[aria-expanded="true"] {
    transform: rotate(180deg);
}

.connect-content {
    
    
    max-width: 700px;
    margin: 20px auto;
}

.p-bg{
    background-color: #000;
    padding:8px;
    border-radius: 10px;
    color:#fff;
    width:30%;
    text-align: center;
}

.connect-box{
    background-color: transparent;
    padding:24px;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
}

.header {
    width: 100%;
}

.header-content {
    max-width: 1100px;
    margin: auto;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.row{
    display:flex;
    flex-direction: row;
}

.container-hf{
    display: flex;
    align-items: center;
    flex-direction: row;
    height:10vh;
    margin-left:10%;
    margin-right:13%;
}

.social-icons{
    display:flex;
    flex-direction: row;
    gap:24px;
}

.img-icon{
    width:18;
    height:auto;
}

.container-header{
    background-color: rgba(23, 26, 29,1);
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.container-footer{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left:10%;
    margin-right:13%;
    height:10vh;
    
}

.tooltip {
    position: relative;
}
  
/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #454545;
    color: #fff;
    padding: 11px;
    border-radius: 12px;
    position: absolute;
    z-index: 1;
}

.tooltip .tooltiptext {
    max-width: 300px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
}

@media only screen and (max-width: 768px)  {
    .container-footer{
        justify-content: center;
        text-align: center;
        flex-direction:column;
    }

    .footer-left{
        justify-content: center;
        text-align: center;
    }

    .deposit-button-container{
        flex-direction:column;
    }
    
}

.header-btn{

    background-color: #36d2cd;
    border-radius: 10px;
    padding:0px;
    text-align: center;
    cursor: poInter;
    color: #fff;

    transition: 0.3s;
}

.nopad{
    padding:0px;
}

.header-btn:hover{
    
    box-shadow: 0px 4px 15px rgba(54, 210, 205,0.4);
}

.justify-left{
    margin-right:auto;
}
.justify-right{
    float: right; 
    margin-left: auto;
}

.header-msg {
    width: 100%;
    height:48px;
    align-items: center;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-left{
    margin-right:auto;
    
}

.footer-center{
    text-align: center;
    margin:auto;
}

.center-self {
    margin: 0 auto;
}

.swap {
    max-width: 600px;
    margin-top: 80px!important;
}

.swap > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swap > div:last-child {
    padding: 50px;
}

.swap > div:first-child > button {
    padding: 12px 40px;
    border-bottom: 0;

    z-index: 1000;
    background-color: transparent;
    color: #fff;
    border: 1px solid rgba(255,255,255,0.3);
    
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: 0.3s;
    
}

.swap > div:first-child > button:hover {
    background-color: #36d2cd;
    cursor:pointer;
    color:#fff;
} 


.swap > div:first-child > button.current {
    background: #36d2cd !important;
    color: #fff;
    cursor:pointer;
}

.swap > div:first-child > button:last-child{
    margin-right: 20px;
}

.swap > div:first-child > button:first-child{
    margin-left: 20px;
}

.swap > div:first-child > button:after,
.amount-btn.is-success:after {
    display: none;
    
}

.swap .default-amounts {
    margin-top: 40px;
}

.deposit-button-container{
    width:100%;
    margin:auto;
    gap:20px;
    display:flex;
    flex-direction: row;

    
}

.tx-complete-input{
    width:100%;
    background-color:rgba(0,0,0,0);
    border:0px solid #fff;
    color:#fff;
    font-size:24px;
}

.tx-complete-btn{
    width: 40%;
    cursor:pointer;
    background-color: #36d2cd;
    text-align: center;
    padding:8px;
    border-radius: 10px;
    color:#fff;
    font-size:16px;
    border:0px solid #fff;
    transition: 0.3s;
}

.copy-clipboard-btn{
    width: 30%;
    margin:auto;
    cursor:pointer;
    background-color: #36d2cd;
    text-align: center;
    padding:6px;
    border-radius: 10px;
    color:#fff;
    font-size:16px;
    border:0px solid #fff;
    transition: 0.3s;
}

.copy-clipboard-btn:hover{
    
    box-shadow: 0px 4px 15px rgba(54, 210, 205,0.4);
}

.tx-complete-btn:hover{
    
    box-shadow: 0px 4px 15px rgba(54, 210, 205,0.4);
}

.dialog-box{
    background-color: #171a1d;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    border:1px solid rgba(255,255,255,0.1);
    top: 30%;
    z-index: 100000;
}

.wd-status{
    display: flex;
    flex-direction: column;
}

.left{
    text-align: left;
}

.right{
    text-align: right;
}
.connect-button{
    width: 40%;
    cursor:pointer;
    background-color: #36d2cd;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap:5px;
    text-align: center;
    padding:16px;
    border-radius: 10px;
    color:#fff;
    font-size:16px;
    transition: 0.3s;
    border:0px solid #fff;
}

.connect-button:hover{
    box-shadow: 0px 4px 20px rgba(54, 210, 205,0.5);
    
}

.deposit-button{
    width: 100%;
    cursor:pointer;
    background: #36d2cd !important;
    text-align: center;
    padding:16px;
    border-radius: 10px;
    color:#fff;
    font-size:16px;
    transition: 0.3s;
    border:0px solid #fff;
}

.deposit-button:hover{
    box-shadow: 0px 4px 20px rgba(54, 210, 205,0.5);
    
}

.token-select-container{
    margin:auto;
    width:80%;
}

.token-select{
    width:100%;
    margin:auto;
    background-color: rgba(0,0,0,0);
    color:#fff;
    text-align: center;
    font-size: 18px;
    border:1px solid rgba(255,255,255,0.5);
    padding:8px;
    border-radius: 10px;
}

.token-select:focus {
    background-color: rgba(54, 210, 205,0.1);
}

.token-select-option{
    width: 100%;
    color: #fff;
    background-color: #000;
}

.token-withdraw-input{
    width:100%;
    margin:auto;
    background-color: rgba(0,0,0,0);
    color:#fff;
    text-align: center;
    font-size: 18px;
    border:1px solid rgba(255,255,255,0.5);
    padding:8px;
    border-radius: 10px;
}

.swap .default-amounts > p{
    margin-bottom: 15px;
}

.swap .amounts {
    display:flex;
    flex-direction: row;
    gap:30px;
    margin-bottom:20px;
}

.swap .default-amounts .amounts:not(:first-child) {
    margin-top: 30px;
}

label {
    margin-bottom: 15px;
}

.swap .status {
    margin-top: 40px;
}

.amount-btn.is-success{
    width: 100%;
    /* background: white !important; */
    color: #fff;
    cursor:pointer;
}

.nes-badge {
    width: 100%;
    cursor:pointer;

}

.amount-btn{
    width: 100%;
    cursor:pointer;
    background-color: transparent;
    border: 2px solid #36d2cd;
    text-align: center;
    padding: 10px 6px;
    border-radius: 10px;
    transition:0.3s;
}

.amount-btn:hover{
    box-shadow: 0px 4px 20px rgba(54, 210, 205,0.5);
    
}

.amount-btn a{
    width:100%;
    text-align: center;
}

#deposit-dialog,
#swap-dialog {
    z-index: 10000;
    top: 36%;
}

#deposit-dialog[open] + #overlay,
#swap-dialog[open] + #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.footer {
    color: #fff;
}

.footer .content {
    text-align: center;
    padding: 0 0 40px 0;
}

.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.nav {
    display: flex;
}

.nav-item {
    padding: 10px;
    margin-right: 3px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
}

.nav-item.selected,
.nav-item:hover {
    border-bottom-color: #36d2cd;
}

.w-100 {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .container-hf{
        margin-left:4%;
        margin-right:4%;
    }

    .header-content h2 {
        font-size: 14px;
    }

    .header-content p,
    .header-content button {
        font-size: 11px!important;
    }

    .swap button,
    .swap .nes-badge,
    .swap label,
    .swap p {
        font-size: 12px;
    }

    .p-bg{
        width:40%;

    }

}

@media screen and (max-width: 500px) {
    .swap > div:last-child {
        padding: 30px;
    }

    .swap > div:first-child > button {
        font-size: 12px;
        padding: 10px 15px;
    }

    .swap button,
    .swap .nes-badge,
    .swap label,
    .swap p {
        font-size: 11px;
    }

    .header-content button {
        font-size: 10px!important;
        padding: 3px;
    }

    .header {
        padding: 0 10px;
    }

    .body-content {
        min-height: 100vh;
    }
}